<template>
  <div>
    <div class='main-page-content'>
      <div class='table-search'>
        <el-form :model='searchCondition' inline size='mini'>
          <el-form-item label='品牌'>
            <el-input v-model='searchCondition.brand_name' placeholder='品牌，模糊匹配' clearable></el-input>
          </el-form-item>
          <el-form-item label='对接人'>
            <!--            <el-input v-model='searchCondition.contact_name' placeholder='对接人' clearable></el-input>-->
            <el-select v-model='searchCondition.contact_name' filterable clearable>
              <el-option v-for='(name,index) in options' :key='index' :value='name' :label='name'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='对接时间'>
            <!--              <el-input v-model='searchCondition.contact_at' placeholder='对接时间' clearable></el-input>-->
            <el-date-picker
              v-model='searchCondition.contact_at'
              type='date'
              format='yyyy年MM月dd日'
              value-format='yyyy-MM-dd'
              placeholder='选择日期'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model='searchCondition.show_repeat' @change='handleQuery'>显示重复</el-checkbox>
          </el-form-item>

          <!--            <el-form-item label='创建时间'>-->
          <!--              <el-date-picker-->
          <!--                v-model='searchCondition.date_range'-->
          <!--                type='daterange'-->
          <!--                align='right'-->
          <!--                unlink-panels-->
          <!--                range-separator='至'-->
          <!--                start-placeholder='开始日期'-->
          <!--                end-placeholder='结束日期'-->
          <!--                value-format='yyyy-MM-dd'-->
          <!--                :picker-options='pickerOptions'>-->
          <!--              </el-date-picker>-->
          <!--            </el-form-item>-->
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                       @click='handleQuery'>搜索
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-plus' v-if='hasAuthority("brand_join_record_create")'
                       @click='handleAdd'>新增
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon='el-icon-upload' v-if='hasAuthority("brand_join_record_import")' @click='openImport=true'>
              导入
            </el-button>
          </el-form-item>
          <el-form-item>
            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("brand_join_record_export")'
                        @export='handleExport'></export-btn>
          </el-form-item>
          <el-form-item>
            <LogPanel ref='refLogPanel' module-name='brand_join_records'></LogPanel>
          </el-form-item>
        </el-form>
      </div>
      <!--        操作栏-->
      <!--        <el-row class='mb8' :gutter='10'>-->
      <!--          <el-col :span='1.5'>-->

      <!--          </el-col>-->
      <!--          <el-col :span='1.5'>-->

      <!--          </el-col>-->
      <!--          <el-col :span='1.5'>-->
      <!--           -->
      <!--          </el-col>-->
      <!--          <el-col :span='1.5'>-->

      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--    渲染表格-->
      <div class='default-table'>
        <el-table :data='dataList'
                  @selection-change='handleSelectionChange'
                  @sort-change='changeTableSort'
                  @row-click='rowClickEvent'>
          <el-table-column type='selection' width='55'></el-table-column>
          <el-table-column type='index' label='序号' width='88'></el-table-column>
          <el-table-column prop='id' label='编号' width='88'></el-table-column>
          <el-table-column prop='brand_name' label='品牌' sortable='custom' min-width='300'>
            <template slot-scope='{row}'>
              <span :class='[row.same_count>1?"danger-table-row":""]' @dblclick='handleShowCurr(row)'>{{ row.brand_name
                }}</span>
              <el-link v-if='row.same_count>1' icon='el-icon-reading' style='margin-left: 10px' title='双击显示该品牌数据'
                       @click='handleShowCurr(row)'>重复数：{{ row.same_count }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop='contact_name' label='对接人' sortable='custom' min-width='160'></el-table-column>
          <el-table-column prop='contact_at' label='对接时间' sortable='custom' width='140'></el-table-column>
          <el-table-column align='center' width='160' label='操作'>
            <template slot-scope='{row,$index}'>
              <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("brand_join_record_edit")'
                         @click='handleEdit(row)'>修改
              </el-button>
              <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("brand_join_record_delete")'
                         @click='handleDelete(row,$index)'>删除
              </el-button>
              <!--              <el-button type='text' icon='el-icon-log'-->
              <!--                         @click='handleLog(row)'>日志-->
              <!--              </el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <div style='display: flex;flex-direction: row;justify-content: space-between;align-items: center'>
          <div>
            <el-button type='danger' icon='el-icon-delete'
                       :disabled='!multiple||loadingDelete'
                       :loading='loadingDelete'
                       v-if='hasAuthority("brand_join_record_delete")'
                       @click='handleRemoveSelection'>批量删除
            </el-button>
          </div>
          <m-pagination @pagination='getList' :page.sync='pageData.current_page' :limit.sync='pageData.page_size'
                        :total.sync='total'></m-pagination>
        </div>
        <!--          <MlPageTable :all-columns='defaultColumns'-->
        <!--                       :table-name='tableName'-->
        <!--                       :data-list='dataList'-->
        <!--                       :limit.sync='pageData.page_size'-->
        <!--                       :page.sync='pageData.current_page'-->
        <!--                       :total.sync='total'-->
        <!--                       highlight-current-row-->
        <!--                       :row-class-name='tableRowClassName'-->
        <!--                       @row-click='rowClickEvent'-->
        <!--                       @getList='getList'>-->
        <!--            <el-table-column align='center' width='120' label='操作'>-->
        <!--              <template slot-scope='{row,$index}'>-->
        <!--                <el-button type='text' icon='el-icon-edit' v-if='hasAuthority("brand_join_record_edit")' @click='handleEdit(row)'>修改</el-button>-->
        <!--                <el-button type='text' icon='el-icon-delete' v-if='hasAuthority("brand_join_record_delete")' @click='handleDelete(row,$index)'>删除</el-button>-->
        <!--              </template>-->
        <!--            </el-table-column>-->
        <!--          </MlPageTable>-->
      </div>
    </div>

    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='600px' append-to-body :close-on-click-modal='false'>
      <el-form ref='form' :model='form' :rules='rules' size='small' label-width='100px'>
        <el-form-item label='品牌' prop='brand_name'>
          <el-input v-model='form.brand_name' placeholder='请输入品牌' maxlength='100' show-word-limit clearable />
        </el-form-item>
        <el-form-item label='对接人' prop='contact_name'>
          <el-input v-model='form.contact_name' placeholder='请输入对接人' maxlength='50' show-word-limit clearable />
        </el-form-item>
        <el-form-item label='对接日期' prop='contact_at'>
          <!--            <el-input v-model='form.contact_at' placeholder='请输入对接时间' clearable/>-->
          <!--            <el-date-picker-->
          <!--              v-model="form.contact_at"-->
          <!--              type="datetime"-->
          <!--              placeholder="选择日期时间">-->
          <!--            </el-date-picker>-->
          <el-date-picker
            v-model='form.contact_at'
            type='date'
            format='yyyy年MM月dd日'
            value-format='yyyy-MM-dd'
            placeholder='选择日期'>
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
        </el-button>
        <el-button @click='cancel'>取 消</el-button>
      </div>
    </el-dialog>
    <!--      导入-->
    <ImportBrandJoinRecord :visible.sync='openImport' v-if='hasAuthority("brand_join_record_import")'
                           @imported='handleQuery' />



  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportBrandJoinRecord from './ImportBrandJoinRecord'
import LogPanel from '@/components/logs/LogPanel'

export default {
  name: 'BrandJoinRecordList',
  components: { LogPanel, ImportBrandJoinRecord, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'brand_join_record',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: {
        date_range: [],
        brand_name: null,
        contact_name: null,
        contact_at: null,
        is_import: null,
        show_repeat: false
      },
      pageData: { current_page: 1, page_size: 15 },
      orderBy: { id: 'desc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: false, prop: 'id', label: '序号', width: 55, sortable: false, fixed: false },
        { hide: false, prop: 'brand_name', label: '品牌', width: 140, sortable: true, fixed: false },
        { hide: false, prop: 'contact_name', label: '对接人', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'contact_at', label: '对接日期', width: 100, sortable: true, fixed: false },
        // { hide: false, prop: 'is_import', label: '是否导入', width: 120, sortable: true, fixed: false },
        // { hide: false, prop: 'created_by', label: '创建人', width: 120, sortable: true, fixed: false },
        // { hide: false, prop: 'updated_by', label: '修改人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: false, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false }],
      // 弹出层标题
      title: '添加/修改-品牌对接记录',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        brand_name: [{ required: true, message: '品牌不能为空', trigger: 'blur' }],
        contact_name: [{ required: true, message: '对接人不能为空', trigger: 'blur' }],
        contact_at: [{ required: true, message: '对接日期不能为空', trigger: 'blur' }]
        // is_import: [{ required: true, message: '是否导入不能为空', trigger: 'blur' }],
        // created_by: [{ required: true, message: '创建人不能为空', trigger: 'blur' }],
        // updated_by: [{ required: true, message: '修改人不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:brand_join_record',
      ids: [],
      multiple: false,
      loadingDelete: false,
      options: []
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderBy = orderSort
      this.getList()
    },
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.same_brand_count && row.same_brand_count > 1) {
        return 'danger-table-row'
      } else
        return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages, options } = await this.$api.getBrandJoinRecordList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 10
      this.total = pages.total
      this.loading = false
      this.loadOptions(options)
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    async delRow(id) {
      this.loadingDelete = true
      await this.$api.deleteBrandJoinRecord(id)
      this.loadingDelete = false
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-品牌对接记录'
      // let { info } = await this.$api.getBrandJoinRecordInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-品牌对接记录'
      // let { info } = await this.$api.getBrandJoinRecordInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveBrandJoinRecord(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportBrandJoinRecords(searchCondition)
        let name = `品牌对接记录`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    handleRemoveSelection() {
      this.$confirm(`确认删除选中的[${this.ids.length}]条数据，是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.delRow(this.ids)

        setTimeout(() => {
          this.handleQuery()
        }, 1000)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    handleSelectionChange(val) {
      this.ids = []
      if (val && val.length > 0) {
        this.ids = val.map((item) => {
          return item.id
        })
      }
      this.multiple = this.ids.length > 0

    },
    loadOptions(options) {
      if (options) {
        const contact_names = options['contact_names']
        if (contact_names && contact_names.length > 0) {
          this.options = contact_names
        }
      }
    },
    handleShowCurr(row) {
      this.searchCondition.brand_name = row.brand_name
      this.handleQuery()
    },
    handleLog(row) {
      console.log(row)
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style>
.danger-table-row {
  /*border: rgba(255, 0, 0, 0.8) 1px dashed ;*/
  background-color: rgba(255, 73, 73, 1) !important;
  padding: 10px 10px;
  border-radius: 5px !important;
  color: #f2f2f2;
}
</style>
