<template>
  <div>
    <el-button icon='el-icon-tickets' @click='show'>日志</el-button>
    <el-drawer title='操作日志'
               :visible.sync='showLog'
               direction='rtl'
               size='70%'>
      <div class='default-table' style='padding-left: 10px;'>
        <!--    渲染表格-->
        <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                 :loading='loadingStatus' @selection-change='handleSelectionChange' border>
        </m-table>
        <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
          <el-button type='text' icon='el-icon-refresh' @click='getList' :disabled='loadingStatus'
                     :loading='loadingStatus' style='line-height: 30px'>刷新
          </el-button>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
export default {
  name: 'LogPanel',
  data() {
    return {
      showLog: false,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      loadingStatus: false,
      searchCondition: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '日志内容',
          value: 'content',
          width: 400,
          header_align: 'center',
          align: 'left'
        },
        {
          title: '记录时间',
          value: 'created_at',
          width: 120
        }
        // {
        //   title: '修改时间',
        //   value: 'updated_at',
        //   width: 120
        // }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 20,
        current_page: 1
      }
    }
  },
  props: {
    moduleName: {
      type: String,
      required: true
    }
  },
  methods: {
    show() {
      this.showLog = true
      this.searchCondition.module_name = this.moduleName
      this.getList()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.module_name) {
        condition.module_name = this.searchCondition.module_name
      }

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // condition['info_id'] = this.infoId
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getOperationLogList(searchCondition)
      // let { list, pages } = await this.$api.getLbDressSelectionLog(searchCondition)

      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size

      })
      setTimeout(() => {
        this.loadingStatus = false
      }, 500)
    }
  },
  created() {

    // this.getList()
  }
}
</script>

<style scoped>

</style>
